import { connect } from 'react-redux';

import { Fade } from '@mui/material';

import { Carousel } from 'react-responsive-carousel';

import { imagesArray } from './imagesArray/imagesArray';

import { useImagesCarouselStyles } from './imagesCarouselStyles.styles';

export const ImagesCarousel = ({ reduxProps }) => {
  const classes = useImagesCarouselStyles();

  const { currentLayer } = reduxProps;

  const carouselProps = () => ({
    showStatus: false,
    showIndicators: false,
    autoPlay: true,
    infiniteLoop: true,
    centerMode: true,
    centerSlidePercentage: 100,
    showThumbs: false,
    interval: 1500,
    transitionTime: 500,
    animationHandler: 'fade'
  });

  return (
    <Fade in={currentLayer === 1} timeout={500}>
      <div className={classes.images_carousel_wrapper}>
        <Carousel
          {...carouselProps()}
          className={classes.images_carousel}
        >
          {imagesArray.map((img, index) => (
            <img
              key={index}
              className={classes.carouselImg}
              alt={`carousel_img-${index}`}
              src={img.src}
            />
          ))}
        </Carousel>
      </div>
    </Fade>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    currentLayer: currentState.appReducer.currentLayer,
  }
});

export default connect(mapStateToProps)(ImagesCarousel);