import { makeStyles } from '@mui/styles';

export const useBlockWrapperStyles = makeStyles((theme) => ({
  blocks_wrapper: ({ threshold, backgroundColor }) => ({
    boxSizing: 'border-box',

    width: '100%',
    height: '100%',

    overflow: 'auto',
    overscrollBehavior: 'contain',

    padding: '1rem',
    paddingTop: `calc(${(threshold - 76) / 16}rem)`,

    transition: 'background-color .2s ease-in-out',

    backgroundColor,

    position: 'absolute',
    bottom: '0',
    zIndex: '3',

    [theme.breakpoints.up('md')]: {
      padding: '1rem',
      paddingLeft: 'calc(50vw + 1rem)',

      transition: 'unset',

      backgroundColor: 'unset'
    }
  })
}));