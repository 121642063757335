
import { connect } from 'react-redux';

import clsx from 'clsx';

import LogoAnimation from './logo-animation/logoAnimation';

import { layer1Styles } from './Layer1.styles';

const Layer1 = ({ reduxProps }) => {
  const classes = layer1Styles();
  
  const { currentLayer } = reduxProps;

  return (
    <div
      id="layer_1"
      className={clsx(
        classes.layer_one,
        currentLayer === 1 ? classes._open : classes._closed
      )}
    >
      <LogoAnimation />
    </div>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    currentLayer: currentState.appReducer.currentLayer
  }
}); 

export default connect(mapStateToProps)(Layer1);