// REDUX
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/rootReducer' ;
// import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

/*
  Redux has been added after the whole project was made, so I didnt
  had the time to migrate the whole infrastructure to Redux. So if 
  you're wondering why some parts of the project use it while others
  do not, there's you answer.
*/

export const store = createStore(rootReducer);
