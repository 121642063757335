const INITIAL_STATE = {
  layerThree: {
    cleaningData: false,
    textColor: '',
    backgroundColor: '',
    title: '',
    headerText: '',
    bodyText: '',
    images: []
  }
};

export const layerThree = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_LAYER_THREE_CONTENT':
      return {
        ...currentState,
        layerThree: {
          ...currentState.layerThree,
          textColor: action.payload.textColor,
          backgroundColor: action.payload.backgroundColor,
          headerText: action.payload.headerText,
          bodyText: action.payload.bodyText,
          images: action.payload.images
        }
      };
    case 'SET_CLEANING_DATA':
      return {
        ...currentState,
        layerThree: {
          ...currentState.layerThree,
          cleaningData: action.payload,
        }
      };
    case 'CLEAR_LAYER_THREE':
      return INITIAL_STATE;
    default:
      return currentState;
  }
};

export const setLayerThreeContent = (layerThreeContent) => ({
  type: 'SET_LAYER_THREE_CONTENT',
  payload: layerThreeContent
});

export const setCleaningData = (boolean) => ({
  type: 'SET_CLEANING_DATA',
  payload: boolean
});

export const clearLayerThree = () => ({
  type: 'CLEAR_LAYER_THREE'
});
