import { useState, useRef, useEffect } from 'react';

import { useTheme, useMediaQuery, Typography, Fade } from '@mui/material';

import useSize from '@react-hook/size';

import clsx from 'clsx';

import { useBlockStyles } from '../../block.styles';
import { useAnimatedTextStyles } from './animatedText.styles';

export const AnimatedText = ({ drilledProps }) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [lastWordTranslateY, setLastWordTranslateY] = useState(0);

  const classes = useAnimatedTextStyles({ showPhoneNumber, lastWordTranslateY });

  const { id, activeBlock } = drilledProps;

  const block_classes = useBlockStyles({
    id,
    activeBlockId: activeBlock.id,
    textColor: activeBlock.textColor
  });

  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  // So we can listen to its width changes;
  const lastWordRef = useRef(<span />);
  const [lastWordWidth] = useSize(lastWordRef);

  // So we can get their width
  const phoneNumberTextRef = useRef(<a />);
  const callTextRef = useRef(<a />);

  const handleLastWordTranslateY = () => {
    if (showPhoneNumber) {
      setLastWordTranslateY(callTextRef.current.offsetWidth);
    } else {
      setLastWordTranslateY(phoneNumberTextRef.current.offsetWidth);
    }
  }

  const handleClick = () => {

    if (!mobileViewport) {
      handleLastWordTranslateY();
    
      setShowPhoneNumber(!showPhoneNumber);
    }

    return null
  }

  useEffect(() => {

    setLastWordTranslateY(callTextRef.current.offsetWidth);

  }, [lastWordWidth])

  return (
    <div className={classes.animated_text}>
      <Typography
        ref={phoneNumberTextRef}
        variant="body_bold_underlined"
        component="a"
        className={clsx(
          block_classes.block__text,
          block_classes.block__cursor_text,
          classes.animated_text__text,
          !showPhoneNumber && classes._hidden,
        )}
        onClick={handleClick}
      >
        +33640625150
      </Typography>
      <Typography
        ref={callTextRef}
        variant="body_bold_underlined"
        component="a"
        className={clsx(
          block_classes.block__text,
          block_classes.block__cursor_text,
          classes.animated_text__text,
          showPhoneNumber && classes._hidden
        )}
        onClick={handleClick}
        href={mobileViewport && 'tel: +33640625150'}
      >
        call
      </Typography>
      <Typography
        ref={lastWordRef}
        variant="body_bold"
        component="span"
        className={clsx(
          block_classes.block__text,
          classes.animated_text__last_word
        )}
      >
        {' '}
        us.
      </Typography>
    </div>
  );
};
