import { useState, useEffect, useRef } from 'react';
// REDUX
import { connect } from 'react-redux';
import { toggleOpacityPanel, setPreviousLayer, setCurrentLayer } from '../../../redux/reducers/app/app';
// MATERIAL UI
import { useMediaQuery } from '@mui/material';
// LOTTIE
import Lottie from 'lottie-react';
import logoAnimation from '../../../assets/lotties/logoAnimation.json';
// STYLES
import { logoStyles } from './logoAnimation.styles';

// Docs for the logo animation:
// https://lottiereact.com/
const LogoAnimation = ({ reduxActions }) => {
  const [animationStarted, setAnimationStarted] = useState(false);

  const classes = logoStyles();
  const mobileViewport = useMediaQuery('(max-width: 1279px)');

  const {
    setPreviousLayer,
    setCurrentLayer,
    toggleOpacityPanel
  } = reduxActions;

  const lottieRef = useRef();

  const triggerAnimation = () => {
    // Currently, the animation has a bug that causes the animation
    // to run again after it has been completed. This prevents it
    // from happening
    setAnimationStarted(true);

    lottieRef.current?.play();
    lottieRef.current?.setDirection(1);

    if (!animationStarted) {
      lottieRef.current?.playSegments([0, 47], true);
    }
  }

  const triggerAnimationInReverse = () => {
    lottieRef.current?.play();
    lottieRef.current?.setDirection(-1);
  }

  useEffect(() => {

    if (mobileViewport) {

      setTimeout(() => {
        triggerAnimation();
      }, 2500);
  
      setTimeout(() => {
        triggerAnimationInReverse();
      }, 5000);
    }

  }, [mobileViewport]);

  return (
    <div
      className={classes.logo}
      onClick={() => {
        setCurrentLayer(2);
        setPreviousLayer(1);
      }}
      onMouseEnter={() => {
        if (!mobileViewport) {
          triggerAnimation();
          toggleOpacityPanel();
        }
      }}
      onMouseLeave={() => {
        if (!mobileViewport) {
          triggerAnimationInReverse();
          toggleOpacityPanel();
        }
      }}
    >
      <Lottie
        lottieRef={lottieRef}
        animationData={logoAnimation}
        autoplay={false}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setPreviousLayer: (previousLayer) => dispatch(setPreviousLayer(previousLayer)),
    setCurrentLayer: (layer) => dispatch(setCurrentLayer(layer)),
    toggleOpacityPanel: () => dispatch(toggleOpacityPanel())
  }
});

export default connect(null, mapDispatchToProps)(LogoAnimation);
