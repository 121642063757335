// ASB = Active Block
// PSB = Previous Block

function updatedPBTrigger (previousBlockState, updatedTrigger) {
  return {
    ...previousBlockState,
    trigger: updatedTrigger
  };
}

function updatedPB (currentLayerTwoState) {
  const blocksArray = currentLayerTwoState.blocksArray;
  const currentAB = currentLayerTwoState.activeBlock;

  const nextBlock = blocksArray.find(
    (block) => block.id === currentAB.id - 1
  );

  if (nextBlock) {
    return nextBlock;
  }

  return {};
}

export function handlePreviousBlockUpdate (currentLayerTwoState, trigger) {
  
  if (trigger > currentLayerTwoState.threshold) {
    return {
      ...currentLayerTwoState,
      previousBlock: updatedPB(currentLayerTwoState),
      activeBlock: {
        ...currentLayerTwoState.previousBlock
      }
    };
  }

  return {
    ...currentLayerTwoState,
    previousBlock: updatedPBTrigger(
      currentLayerTwoState.previousBlock,
      trigger
    )
  };
}
