// REDUX
import { connect } from 'react-redux';
import { setPreviousLayer, setCurrentLayer } from '../../../redux/reducers/app/app';
// MATERIAL UI
import { Fade, useMediaQuery } from '@mui/material';
import { ReactComponent as CloseIcon }  from '../../../assets/icons/close-icon.svg';
// STYLES
import { closeButtonStyles } from './closeButton.styles';


const CloseButton = ({ reduxProps, reduxActions }) => {

  const mobileViewport = useMediaQuery('(max-width: 1280px)'); // Smartphones and tablets
  
  const {
    currentLayer,
    userBrowser,
    backgroundColor,
    activeBlockTextColor,
    layerThreeTextColor
  } = reduxProps;

  const classes = closeButtonStyles({
    backgroundColor,
    textColor: currentLayer === 3 ? layerThreeTextColor : activeBlockTextColor
  });

  const { setPreviousLayer, setCurrentLayer } = reduxActions;

  const handleClose = () => {
    setPreviousLayer(currentLayer === 2 ? 2 : currentLayer === 3 ? 3 : 1);
    setCurrentLayer(currentLayer === 2 ? 1 : currentLayer === 3 ? 2 : 1);
  };
  
  return (
    <Fade in={currentLayer !== 1} timeout={currentLayer !== 1 ? 1000 : 200}>
      <div className={classes.closeButtonWrapper}>
        <button className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeButton__icon} />
          {mobileViewport && userBrowser !== 'safari' ? (
            <div className={classes.closeButton__backgroundFilter}/>
          ) : null}
        </button>
      </div>
    </Fade>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    currentLayer: currentState.appReducer.currentLayer,
    backgroundColor: currentState.appReducer.backgroundColor,
    activeBlockTextColor: currentState.layerTwoReducer.layerTwo.activeBlock.textColor,
    layerThreeTextColor: currentState.layerThreeReducer.layerThree.textColor,
    userBrowser: currentState.userDataReducer.userData.browser,
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setPreviousLayer: (previousLayer) => dispatch(setPreviousLayer(previousLayer)),
    setCurrentLayer: (layer) => dispatch(setCurrentLayer(layer)),
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseButton);