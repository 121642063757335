

export function handleSetBlocksColors (currentBlocksData, rawColorsArray) {

  const updatedBlocksData = [];

  const cleanedColorsArray = rawColorsArray.filter((entry) => entry.title);

  currentBlocksData.forEach((block) => {
    let blockDataFromCMS = cleanedColorsArray.find((entry) => entry.title === block.title);

    if (blockDataFromCMS) {
      updatedBlocksData.push({
        ...block,
        textColor: blockDataFromCMS.textcolor,
        backgroundColor: blockDataFromCMS.layer2backgroundhex
      });
    }
  })

  return updatedBlocksData;
} 