import { makeStyles } from '@mui/styles';

export const useBlockStyles = makeStyles((theme) => ({
  block: ({ lastBlockMarginBottom }) => ({
    cursor: 'default',

    marginBottom: lastBlockMarginBottom ? lastBlockMarginBottom : '2rem'
  }),
  block_text_wrapper: {
    transition: 'opacity .2s ease-in-out',
    opacity: ({ id, activeBlockId }) => (activeBlockId === id ? '1' : '0.05'),

    [theme.breakpoints.up('md')]: {
      opacity: ({ id, activeBlockId, imageToBeHighlightedId }) =>
        activeBlockId === id && !imageToBeHighlightedId ? '1' : '0.05'
    }
  },
  block__text: ({ textColor }) => ({
    color: textColor,
    transition: 'opacity .2s ease-in-out, color .2s ease-in-out',

    [theme.breakpoints.up('md')]: {
      maxWidth: '46.875rem' // 750px
    }
  }),
  block__cursor_text: ({ id, activeBlockId, cleaningData }) => ({
    cursor: id === activeBlockId ? 'pointer' : 'default',
    pointerEvents: id === activeBlockId && !cleaningData ? 'all' : 'none'
  }),
  block__clamped_text: {
    overflow: 'hidden',

    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical'
  },
  // MODIFIYERS
  _hidden: {
    opacity: '0.05'
  }
}));