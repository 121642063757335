/*
  Due the complicated nature of the website's design, we need
  to know the user's browser. 

  We need this because on mobile the browser's navbar is hiding
  the footer, so we need to set its position a little higher on
  every IOS browser exept safari (this only happens on IOS).
*/

export const detectBrowser = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};
