import { makeStyles } from '@mui/styles';

export const useLayerTwoStyles = makeStyles((theme) => ({
  layer_two: {
    width: '100%',
    height: '100%',

    transition: 'all .5s ease-in-out',

    position: 'fixed',
    top: '0',
    zIndex: '4'
  },
  // Modifiyers
  _open: {
    opacity: '1',

    filter: 'blur(0)',

    transform: 'scale(1)'
  },
  _closed: {
    opacity: '0',

    filter: 'blur(1.5rem)',

    pointerEvents: 'none',

    transform: 'scale(0.8)',
  }
}));