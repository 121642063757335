// MATERIAL UI
import { makeStyles } from '@mui/styles';

export const layer3Styles = makeStyles((theme) => ({
  layer_three: {
    width: '100vw',
    height: '100vh',

    boxShadow: 'none',

    transition: 'all .5s ease-in-out',

    position: 'fixed',
    zIndex: '5',
    top: '0',
    left: '0',
  },
  container: {
    width: '100vw',
    height: '100vh',

    paddingTop: 0,
    overflow: 'scroll',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  containerAbsolute: {
    width: '100vw',
    height: '100vh',

    position: 'absolute',
    zIndex: '3',

    pointerEvents: 'none',

    [theme.breakpoints.up('960')]: {
      padding: '1vw 0 0 0'
    }
  },
  leftColumn: {
    paddingTop: '1vw',

    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('1280')]: {
      justifyContent: 'center'
    }
  },
  rightColumn: {
    height: '100vh',

    display: 'flex',
    justifyContent: 'flex-start'
  },
  header: {
    maxWidth: '750px',

    fontSize: '2.4rem',
    lineHeight: '2.8rem',
    fontFamily: 'Graphik',

    whiteSpace: 'pre-wrap',

    padding: '1.5rem 1rem 0 1rem',

    /*
      Designer requested font-sizes change on custom breakpoints
    */
    [theme.breakpoints.up('1300')]: {
      fontSize: '3rem',
      lineHeight: '3.35rem',
      padding: '0'
    },
    [theme.breakpoints.up('1500')]: {
      fontSize: '3.6rem',
      lineHeight: '3.9rem'
    },
    [theme.breakpoints.up('1700')]: {
      fontSize: '4.2rem',
      lineHeight: '4.45rem'
    },
    [theme.breakpoints.up('1900')]: {
      fontSize: '4.8rem',
      lineHeight: '5rem'
    }
  },
  bodyText: {
    maxWidth: '500px',

    padding: '0 1rem 0 1rem',

    fontWeight: '400',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontFamily: 'Graphik',
    letterSpacing: '0.025rem',
    whiteSpace: 'break-spaces',

    '& h1, h2, h3, h4': {
      fontWeight: '400',
      lineHeight: '2rem',
      fontSize: '1.8rem',

      maxWidth: '750px'
    },
    '& a': {
      color: 'inherit',
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      textDecorationThickness: '2px',
      textUnderlineOffset: '2px',

      transition: 'opacity .2s ease-in-out',

      cursor: 'pointer',

      '&:hover': {
        opacity: '1'
      }
    },
    [theme.breakpoints.up('960')]: {
      padding: '0'
    }
  },
  growImageTransition: {
    transition: 'width 0.5s 0s ease-out'
  },
  captionText: {
    fontWeight: '400',
    fontSize: '0.75rem',
    fontFamily: 'Graphik',
    lineHeight: '12px',
    letterSpacing: '0.03em',
    color: 'rgba(0,0,0, 0.5)',

    [theme.breakpoints.up('960')]: {
      position: 'absolute',
      left: '1vw',
      bottom: '5rem'
    },
    [theme.breakpoints.up('1280')]: {
      bottom: '2.5rem'
    }
  },
  portraitImage: {
    transition: 'all .2s ease-in-out',
    maxWidth: '100%',

    // On landscape mode max width needs to be smaller
    '@media (max-width: 960px) and (max-height: 600px)': {
      maxWidth: '55%'
    },
    [theme.breakpoints.up('960')]: {
      maxHeight: '55vh',
      marginBottom: 'unset'
    },
    [theme.breakpoints.up('1280')]: {
      maxHeight: '70vh'
    },
    [theme.breakpoints.up('1536')]: {
      maxHeight: '87vh'
    }
  },
  landscapeImage: {
    height: 'auto',
    transition: 'all .2s ease-in-out',
    maxWidth: '100%',

    '@media (max-width: 960px) and (max-height: 600px)': {
      maxWidth: '95%'
    },
    [theme.breakpoints.up('960')]: {
      width: '47%',
      maxHeight: '80vh',
      maxWidth: 'unset',
      marginBottom: 'unset'
    }
  },
  fullImage_desktop_portrait: {
    maxWidth: '80vw',
    maxHeight: '87vh'
  },
  fullImage_desktop_landscape: {
    maxWidth: '100%',
    maxHeight: '87vh'
  },
  fullImage_portrait: {
    [theme.breakpoints.up('600')]: {
      maxHeight: '70vh'
    },
    [theme.breakpoints.up('800')]: {
      maxHeight: '87vh'
    }
  },
  fullImage_landscape: {
    maxWidth: '100%',
    maxHeight: '55vh'
  },
  phoneAndSmallTabletsImageContainer: {
    position: 'absolute',
    top: '0',

    width: '100%',

    '@media (max-width: 960px) and (max-height: 600px)': {
      top: '1rem',
      left: '1rem'
    }
  },
  phoneAndSmallTabletsImageWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',

    position: 'relative'
  },
  // MODIFIYERS
  _open: {
    filter: 'blur(0)',

    opacity: '1',

    transform: 'scale(1)',
  },
  _closed: {
    filter: 'blur(1.5rem)',

    opacity: '0',

    transform: 'scale(.8)',

    pointerEvents: 'none',
  },
  _marginTop_medium: {
    marginTop: '4rem'
  },
  _visible: {
    // Shows image
    transition: 'all 0.2s ease-out',
    opacity: 1
  },
  _hidden: {
    // Hides image
    transition: 'all 0.2s ease-out',
    opacity: 0
  },
  _textMaxWidth: {
    // Limits text max width
    maxWidth: '500px',
    whiteSpace: 'pre-wrap'
  }
}));
