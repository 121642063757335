import { connect } from 'react-redux';
import {
  setPreviousLayer,
  setCurrentLayer,
  setBackgroundColor,
} from '../../../../../redux/reducers/app/app';
import { setImageToBeHighlighted } from '../../../../../redux/reducers/layer-two/layerTwo';
import { setLayerThreeContent } from '../../../../../redux/reducers/layer-three/layerThree';

import { useTheme, useMediaQuery, Typography } from '@mui/material';

import clsx from 'clsx';

import { useBlockStyles } from '../block.styles';

const ArchivesBlockContent = ({ drilledProps, reduxProps, reduxActions }) => {
  const theme = useTheme();
  const desktopViewport = useMediaQuery(theme.breakpoints.up('md'));

  const { id, textColor, archivesArray } = drilledProps;

  const { activeBlock, imageToBeHighlightedId, currentLayer, cleaningData } = reduxProps;

  const classes = useBlockStyles({
    id,
    activeBlockId: activeBlock.id,
    textColor: activeBlock.textColor,
    cleaningData
  });

  const {
    setLayerThreeContent,
    setPreviousLayer,
    setCurrentLayer,
    setImageToBeHighlighted,
    setBackgroundColor
  } = reduxActions;

  const openLayerThree = (archiveData) => {
    const {
      backgroundHex: backgroundColor,
      bodyTextHex: textColor,
      header: headerText,
      bodytext: bodyText,
      media: images
    } = archiveData;

    setLayerThreeContent({
      textColor,
      backgroundColor,
      headerText,
      bodyText,
      images
    });

    setPreviousLayer(2);
    setCurrentLayer(3);
  };

  const handleMouseEnter = (archiveData) => {
    if (desktopViewport) {
      setImageToBeHighlighted(archiveData.media[0].id);
      setBackgroundColor(archiveData.backgroundHex);
    }

    return null;
  };

  const handleMouseLeave = () => {
    if (desktopViewport) {
      setImageToBeHighlighted(null);
    }

    // This is to prevent the background color from changing
    // during layer transition
    if (currentLayer === 2) {
      setBackgroundColor(activeBlock.backgroundColor);
    }

    return null;
  }

  return archivesArray.map((archiveData, index) => (
    <Typography
      key={index}
      variant="body_underlined"
      onClick={() => openLayerThree(archiveData)}
      onMouseEnter={() => handleMouseEnter(archiveData)}
      onMouseLeave={handleMouseLeave}
      className={clsx(
        classes.block__text,
        classes.block__cursor_text,
        classes.block__clamped_text,
        // This hides the text if imageToBeHighlighted exists,
        // unless you've got your mouse on top of it.
        imageToBeHighlightedId &&
          imageToBeHighlightedId !== archiveData.media[0].id &&
          classes._hidden,
        activeBlock.id !== id && classes._hidden
      )}
    >
      {archiveData.title}
    </Typography>
  ));
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
    imageToBeHighlightedId: currentState.layerTwoReducer.layerTwo.imagesToHighlight.id,
    currentLayer: currentState.appReducer.currentLayer,
    cleaningData: currentState.layerThreeReducer.layerThree.cleaningData
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setPreviousLayer: (layer) => dispatch(setPreviousLayer(layer)),
    setCurrentLayer: (layer) => dispatch(setCurrentLayer(layer)),
    setLayerThreeContent: (layerThreeContent) =>
      dispatch(setLayerThreeContent(layerThreeContent)),
    setImageToBeHighlighted: (imageId) =>
      dispatch(setImageToBeHighlighted(imageId)),
    setBackgroundColor: (color) => dispatch(setBackgroundColor(color))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivesBlockContent);
