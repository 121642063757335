function updatedBlocks (currentBlocks, servicesBlockToAdd) {

  const servicesBlockExists = currentBlocks.find((servicesBlock) => (
    servicesBlock.id === servicesBlockToAdd.id
  ))

  if (servicesBlockExists) {
    return currentBlocks
  }
  
  return [
    ...currentBlocks,
    {
      ...servicesBlockToAdd,
    }
  ]
}

export function handleBlockAddition (currentLayerTwo, payload) {

  if (currentLayerTwo.blocksArray.length === 0) {
    return {
    ...currentLayerTwo,
      activeBlock: {
        ...payload
      },
      blocksArray: updatedBlocks(currentLayerTwo.blocksArray, payload)
    }
  }

  return {
    ...currentLayerTwo,
    blocksArray: updatedBlocks(currentLayerTwo.blocksArray, payload)
  }
}