// MATERIAL UI
import { makeStyles } from '@mui/styles';

export const layer1Styles = makeStyles((theme) => ({
  layer_one: {
    width: '100%',
    height: '100%',

    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '5',

    transition: 'all .5s ease-in-out',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo_animation_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Modifiyers
  _open: {
    filter: 'blur(0)',

    opacity: '1',

    transform: 'scale(1)',
  },
  _closed: {
    filter: 'blur(1.5rem)',

    opacity: '0',

    pointerEvents: 'none',

    transform: 'scale(.8)',
  }
}));
