import { makeStyles } from '@mui/styles';

export const useAnimatedTextStyles = makeStyles((theme) => ({
  animated_text: {
    display: 'inline-block',

    width: 'fit-content',

    position: 'relative'
  },
  animated_text__text: {
    position: 'absolute',
    left: '0',

    opacity: '1',

    transition: 'opacity .2s ease-in-out, color .2s ease-in-out',
  },
  animated_text__last_word: ({ showPhoneNumber, lastWordTranslateY }) => ({
    display: 'inline-block',

    transition: showPhoneNumber
      ? 'opacity .2s ease-in-out, color .2s ease-in-out, transform .1s ease-in-out'
      : 'opacity .2s ease-in-out, color .2s ease-in-out, transform .2s ease-in-out',

    // We divide by 16 to convert to rem
    // and add 0.5 so the text isnt too close
    transform: `translateX(${lastWordTranslateY / 16 + 0.5}rem)`
  }),
  _hidden: {
    opacity: '0',
    pointerEvents: 'none',
  }
}));
