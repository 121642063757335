import { makeStyles } from '@mui/styles';

export const titleStyles = makeStyles({
  title: ({ id, activeBlock }) => ({
    transition: 'all .2s ease-in-out',

    maxWidth: '46.875rem', // 750px

    // activeBlock.id >= id is happens when the title
    // gets hidden by the translate Y on TitlesWrapper.
    opacity: id === activeBlock.id ? '1' : activeBlock.id >= id ? '0' : '.05',

    color: activeBlock.textColor,
  })
});