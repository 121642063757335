// We receive an image tag as a string from the CMS.
// This extracts its source.
function extractImgSrc (imgString) {
  const stringProps = imgString.split(' ');
  const srcEntireProp = stringProps.filter((prop) => prop.slice(0, 3) === `src`);
  const url = String(srcEntireProp).split('"')[1];

  if (url) {
    return url;
  }

  return null;
}

// Gets the first image from each entry
function filledImagesToHightlightArray (entriesData) {
  const imagesToHighlight = [];

  const bioEntryData = entriesData.find((entry) => entry.title === 'Bio');

  imagesToHighlight.push({
    id: bioEntryData.media[0].id,
    src: extractImgSrc(bioEntryData.media[0].img)
  });

  entriesData.forEach((entry) => {
    if (entry.title && entry.title !== 'Bio') {
      imagesToHighlight.push({
        id: entry.media[0].id,
        src: extractImgSrc(entry.media[0].img)
      });
    }
  });

  return imagesToHighlight;
}

export function handleSetArchivesEntriesData (currentLayerTwoState, entriesData) {
  const currentBlocksDataArray = currentLayerTwoState.blocksData;

  entriesData.forEach((entry) => {
    if (entry.title && entry.title !== 'Bio') {
      currentBlocksDataArray[7].archives.push({
        ...entry
      });
    } else if (entry.title === 'Bio') {
      currentBlocksDataArray[0].bioContent = {
        ...entry
      };
    }
  });

  return {
    ...currentLayerTwoState,
    blocksData: currentBlocksDataArray,
    imagesToHighlight: {
      ...currentLayerTwoState.imagesToHighlight,
      images: filledImagesToHightlightArray(entriesData)
    }
  };
}
