import { connect } from 'react-redux';

import { Fade } from '@mui/material';

import { useImagesToHighlightStyles } from './imagesToHighlight.styles';

const ImagesToHighlight = ({ reduxProps }) => {

  const classes = useImagesToHighlightStyles();

  const { imageToHighlightId, imagesToBeHighlightedArray } = reduxProps;

  return (
    <div className={classes.images_to_highlight}>
      <div className={classes.images_wrapper}>
        {imagesToBeHighlightedArray.map((image, index) => (
          <Fade key={index} in={imageToHighlightId === image.id} timeout={200}>
            <img src={image.src} className={classes.images_wrapper__image} />
          </Fade>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    imageToHighlightId: currentState.layerTwoReducer.layerTwo.imagesToHighlight.id,
    imagesToBeHighlightedArray: currentState.layerTwoReducer.layerTwo.imagesToHighlight.images,
  }
})

export default connect(mapStateToProps)(ImagesToHighlight);
