import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { setBackgroundColor } from '../../redux/reducers/app/app';
import { setCleaningData, clearLayerThree } from '../../redux/reducers/layer-three/layerThree';

import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

import clsx from "clsx";
import transformIntoImageTag from '../../helpers/transformIntoImageTag/transformIntoImageTag';

import { ImageContainer } from './image-container/ImageContainer';

import { layer3Styles } from './Layer3.styles';


function Layer3({ reduxProps, reduxActions }) {
  // STATE HOOKS
  const [fullImage, setFullImage] = useState(false); // Whether or not display image in "Fullscreen" mode
  const [imageTagsArray, setImageTagsArray] = useState([]);
  const [currentImage, setCurrentImage] = useState(0); // Index of the image that is being displayed at the moment
  const [marginTopToAdd, setMarginTopToAdd] = useState(0); // Margin top to the header to accomodate the image on top of it
  const [paddingBottomToAdd, setPaddingBottomToAdd] = useState(0); // Padding bottom to add at the bottom of the page (to force scroll)

  // REDUX PROPS & ACTIONS
  const {
    previousLayer,
    currentLayer,
    layerThreeContent,
    activeBlock
  } = reduxProps;

  const { setBackgroundColor, setCleaningData, clearLayerThree } = reduxActions;

  // STYLE HOOKS
  const theme = useTheme();
  const classes = layer3Styles();
  const smartPhoneLandscape = useMediaQuery('(max-width: 950px) and (max-height: 600px)'); // When smartphones are on landscape, display a design that resembles desktop
  const smartPhonesAndSmallTabletsViewport = useMediaQuery('(max-width: 959px)'); // Web designer requested small & large tablets designs be different...
  const desktopViewport = useMediaQuery(theme.breakpoints.up('md'));

  // MISC HOOKS
  const ref = useRef();

  // PROPS
  const TRIGGER_SIZE = 250;
  const layerBio = activeBlock.id === 0 ? true : false;

  // Disable right click in the page
  // document.addEventListener('contextmenu', event => event.preventDefault());

  // Detects when image should fade out and go into full screen mode
  const handleScroll = () => {
    let screenPosition = ref.current.scrollTop;
    const triggerPosition = parseInt(screenPosition / TRIGGER_SIZE);
    const pageBottomContainer = document
      .getElementById('pageBottomContainer_layer3')
      .getBoundingClientRect();

    pageBottomContainer.top <= 0 ? setFullImage(true) : setFullImage(false);

    if (triggerPosition <= imageTagsArray.length) {
      setCurrentImage(triggerPosition);
    }
  };

  const handleImageVisibility = (index) => {
    if (index === imageTagsArray.length - 1 && currentImage >= index && fullImage) {
      return classes._visible;
    }

    if (currentImage === index) {
      return classes._visible;
    }

    return classes._hidden;
  };

  const handleImageOrientation = (orientation) => {
    // If phone and tablet, use this method to create a class
    if (smartPhonesAndSmallTabletsViewport) {
      if (orientation === 'Portrait') {
        return clsx(
          fullImage ? classes.fullImage_portrait : null,
          classes.portraitImage
        );
      } else {
        return clsx(
          fullImage ? classes.fullImage_landscape : null,
          classes.landscapeImage
        );
      }
    } else {
      // else, go back to the old method of creating classes
      if (fullImage) {
        if (orientation === 'Portrait') {
          return classes.fullImage_desktop_portrait;
        }

        return classes.fullImage_desktop_landscape;
      }
      if (orientation === 'Portrait') {
        return classes.portraitImage;
      }
      return classes.landscapeImage;
    }
  };

  /*
    Calculates how much padding to give div at the bottom of the page
    in order to force scroll funcionality.
  */
  const calcPaddingBottomToAdd = () => {
    let numberOfAditionalTriggers = imageTagsArray.length * 60;

    if (layerBio) {
      setPaddingBottomToAdd('50vh');
    } else {
      if (!fullImage) {
        if (currentImage < imageTagsArray.length) {
          setPaddingBottomToAdd('400vh');
        }
      } else {
        setPaddingBottomToAdd(`${numberOfAditionalTriggers}vh`);
      }
    }
  };

  /*
    Calculates margin top of the layer 3 header based on the image's height.
    Only gets called on phones.
  */

  const leftColumnRef = useRef(<div />);
  
  useEffect(() => {
    let images = [];

    if (desktopViewport && !layerBio) {
      layerThreeContent.images.slice(1).map((image) => {
        images.push(transformIntoImageTag(image.img));
        return null;
      });
    } else {
      layerThreeContent.images.map((image) => {
        images.push(transformIntoImageTag(image.img));
        return null;
      });
    }

    setImageTagsArray(images);
  }, [layerThreeContent.images]);

  useEffect(() => {
    if (currentLayer === 3) {
      setBackgroundColor(layerThreeContent.backgroundColor);
    } else {
      setBackgroundColor(activeBlock.backgroundColor);
    }

    /*
      Calculates how much padding bottom to add to
      pageBottomContainer_layer3 div to force scroll functionality
    */
    calcPaddingBottomToAdd();

    ref.current.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      ref.current.removeEventListener('scroll', handleScroll);
    };
  }, [currentLayer, layerThreeContent, imageTagsArray.length]);

  useEffect(() => {
    /*
      Gets activated when you close layer 3. This exists to make
      sure the user always start scrolling from the top.
    */
    if (previousLayer === 3) {
      setCleaningData(true);
      setTimeout(() => {
        setCleaningData(false);
        ref.current.scrollTo(0, 0);
        clearLayerThree();
      }, 500);
    }
  }, [previousLayer])

  return (
    <Grid
      id="layer_three"
      className={clsx(
        classes.layer_three,
        currentLayer === 3 ? classes._open : classes._closed
      )}
    >
      <Grid>
        <Grid container className={classes.containerAbsolute} spacing={0}>
          <Grid
            item
            ref={leftColumnRef}
            xs={12}
            sm={smartPhoneLandscape ? 6 : 12}
            md={6}
            className={
              smartPhonesAndSmallTabletsViewport
                ? classes.phoneAndSmallTabletsImageWrapper
                : null
            }
          >
            {imageTagsArray.map((image, i) => (
              // If phone and tablet, use this method to handle images
              // There's some history as to why this is being set this way, so dont judge.
              smartPhonesAndSmallTabletsViewport ? (
                <ImageContainer 
                  key={i}
                  drilledProps={{
                    layerBio,
                    image,
                    id: i,
                    setMarginTopToAdd,
                    handleImageVisibility,
                    handleImageOrientation,
                  }} 
                />
              ) : (
                // Else go back to the old method of handling images
                <div key={i} className={handleImageVisibility(i)}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: 'auto !important',
                      position: 'fixed',
                      objectFit: 'contain',
                      left: !smartPhonesAndSmallTabletsViewport ? '1vw' : '',
                      bottom: !smartPhonesAndSmallTabletsViewport ? '' : '2vw'
                    }}
                    className={clsx(
                      handleImageVisibility(i),
                      handleImageOrientation(image.orientation)
                    )}
                  />
                  {!layerBio || smartPhonesAndSmallTabletsViewport ? (
                    <Typography className={clsx(classes.captionText)}>
                      {image.alt}
                    </Typography>
                  ) : null}
                </div>
              )
            ))}
          </Grid>
        </Grid>
        <Grid container className={classes.container} spacing={0} ref={ref}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid
            xs={12}
            sm={smartPhoneLandscape ? 6 : 12}
            md={6}
            item
            className={classes.rightColumn}
          >
            <Grid>
              {/* If the layer content is Bio, don't render the header */}
              {!layerBio ? (
                <pre
                  className={classes.header}
                  style={{
                    marginTop:
                      smartPhonesAndSmallTabletsViewport && !smartPhoneLandscape
                        ? `${marginTopToAdd}px`
                        : null,
                    color: layerThreeContent.textColor
                  }}
                  dangerouslySetInnerHTML={{
                    __html: layerThreeContent.headerText
                  }}
                />
              ) : null}

              <pre
                className={clsx(
                  classes.bodyText,
                  classes._textMaxWidth,
                  !layerBio ? classes._marginTop_medium : null
                )}
                style={{
                  // Since bio doesn't have a header, we need to set the margin top on mobile here
                  marginTop:
                    smartPhonesAndSmallTabletsViewport && !smartPhoneLandscape && layerBio
                      ? `${marginTopToAdd + 20}px`
                      : null,
                  color: layerThreeContent.textColor
                }}
                dangerouslySetInnerHTML={{
                  __html: layerThreeContent.bodyText
                }}
              />

              {/* This div adds space to the bottom of the page in order to force scroll funcionality */}
              <div
                id="pageBottomContainer_layer3"
                style={{
                  paddingBottom: paddingBottomToAdd
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (currentState) => ({
  reduxProps: {
    previousLayer: currentState.appReducer.previousLayer,
    currentLayer: currentState.appReducer.currentLayer,
    layerThreeContent: currentState.layerThreeReducer.layerThree,
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setBackgroundColor: (color) => dispatch(setBackgroundColor(color)),
    setCleaningData: (boolean) => dispatch(setCleaningData(boolean)),
    clearLayerThree: () => dispatch(clearLayerThree())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Layer3);