import { makeStyles } from '@mui/styles';

export const homePageStyles = makeStyles((theme) => ({
  homepage: {
    width: '100vw',
    height: '100vh',

    position: 'relative',

    transition: 'background-color .2s ease-in-out',
    backgroundColor: ({ backgroundColor }) => backgroundColor ? backgroundColor : '#000959',
  },
  opacityPanel: {
    width: '100%',
    height: '100%',

    backgroundColor: '#000959',
    opacity: '0',

    pointerEvents: 'none',

    transition: 'opacity 0.5s ease-in-out, background-color .2s ease-in-out',

    position: 'absolute',
    zIndex: '5'
  },
  _hideElement: {
    opacity: '0 !important'
  },
  _increaseOpacity: {
    opacity: '1'
  }
}));
