import { useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import {
  addToBlocksArray,
} from '../../../../redux/reducers/layer-two/layerTwo';

import BioBlockContent from './bio-block-content/BioBlockContent';
import ServicesBlockContent from './services-block-content/ServicesBlockContent';
import ArchivesBlockContent from './archives-block-content/ArchivesBlockContent';
import ContactBlockContent from './contact-block-content/ContactBlockContent';

import { useBlockStyles } from './block.styles';


const Block = ({ drilledProps, reduxActions }) => {

  const {
    id,
    textColor,
    backgroundColor,
    title,
    bioText,
    bioContent,
    services,
    archives,
    lastBlockMarginBottom
  } = drilledProps;

  const { addToBlocksArray } = reduxActions;

  const classes = useBlockStyles({
    lastBlockMarginBottom,
  });

  const block = useRef();

  useEffect(() => {
    if (block.current && textColor) {
      addToBlocksArray({
        id,
        ref: block.current,
        trigger: block.current.getBoundingClientRect().bottom,
        textColor,
        backgroundColor
      });
    }
  }, [addToBlocksArray, backgroundColor, id, textColor]);

  return (
    <div ref={block} className={classes.block}>
      {bioText && (
        <BioBlockContent
          drilledProps={{
            id,
            bioText,
            textColor,
            bioContent
          }}
        />
      )}

      {services && (
        <ServicesBlockContent 
          drilledProps={{
            id,
            textColor,
            servicesArray: services,
          }} 
        />
      )}

      {archives && (
        <ArchivesBlockContent
          drilledProps={{
            id,
            textColor,
            archivesArray: archives
          }}
        />
      )}

      {title === 'Contacts' && (
        <ContactBlockContent
          drilledProps={{
            id,
            textColor,
          }}
        />
      )}
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    addToBlocksArray: (blockData) => dispatch(addToBlocksArray(blockData)),
  }
});

export default connect(null, mapDispatchToProps)(Block);