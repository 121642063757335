import { useEffect, useRef } from 'react';

import { useMediaQuery, Typography } from '@mui/material';

import useSize from '@react-hook/size';

import clsx from 'clsx';

import { layer3Styles } from '../Layer3.styles';

export const ImageContainer = ({ drilledProps }) => {
  const smartPhonesAndSmallTabletsViewport = useMediaQuery('(max-width: 959px)'); // Web designer requested small & large tablets designs be different...

  const classes = layer3Styles();

  const {
    layerBio,
    image,
    id,
    setMarginTopToAdd,
    handleImageVisibility,
    handleImageOrientation
  } = drilledProps;

  const target = useRef(<div />);
  const [_, height] = useSize(target);

  useEffect(() => {

    if (id === 0) {
      setMarginTopToAdd(height)
    }

  }, [target.current, height])

  return (
    <div
      ref={target}
      className={clsx(
        classes.phoneAndSmallTabletsImageContainer,
        handleImageVisibility(id)
      )}
    >
      <img
        src={image.src}
        alt={image.alt}
        className={clsx(handleImageOrientation(image.orientation))}
      />

      {!layerBio ||
        (!smartPhonesAndSmallTabletsViewport && (
          <Typography className={clsx(classes.captionText)}>
            {image.alt}
          </Typography>
        ))}
    </div>
  );
};