const INITIAL_STATE = {
  displayOpacityPanel: false,
  currentLayer: 1,
  previousLayer: 1,
  textColor: '',
  backgroundColor: '',
};

export const app = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TOGGLE_OPACITY_PANEL':
      return {
        ...currentState,
        displayOpacityPanel: !currentState.displayOpacityPanel
      };
    case 'SET_PREVIOUS_LAYER':
      return {
        ...currentState,
        previousLayer: action.payload
      };
    case 'SET_CURRENT_LAYER':
      return {
        ...currentState,
        currentLayer: action.payload
      };
    case 'SET_BACKGROUND_COLOR':
      return {
        ...currentState,
        backgroundColor: action.payload
      };
    default:
      return currentState;
  }
};

export const toggleOpacityPanel = () => ({
  type: 'TOGGLE_OPACITY_PANEL',
})

export const setPreviousLayer = (previousLayer) => ({
  type: 'SET_PREVIOUS_LAYER',
  payload: previousLayer
});

export const setCurrentLayer = (layer) => ({
  type: 'SET_CURRENT_LAYER',
  payload: layer
});

export const setTextColor = (color) => ({
  type: 'SET_TEXT_COLOR',
  payload: color
});

export const setBackgroundColor = (color) => ({
  type: 'SET_BACKGROUND_COLOR',
  payload: color
});