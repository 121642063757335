export const imagesArray = [
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/amfar-cannes-scene2_112341647432.jpg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/B3-FL459_WANYLI_1000RV_20191113112548.jpeg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/aeebe20b79587fea4895cf07ce5845f1.jpg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/DSC_8293.jpeg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/171002-Kostyal-39-scaled-2048x1365.jpg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/DJI_0011-copia.jpeg'
  },
  {
    src: 'https://res.cloudinary.com/arbid/image/upload/v1638635853/171002-Kostyal-10-scaled-2048x1365.jpg'
  }
];