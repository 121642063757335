import { makeStyles } from '@mui/styles';

export const useImagesToHighlightStyles = makeStyles((theme) => ({
  images_to_highlight: {
    boxSizing: 'border-box',

    width: '50%',
    height: '100%',

    padding: '2rem',

    position: 'absolute',
    top: '0',
    zIndex: '5',

    pointerEvents: 'none'
  },
  images_wrapper: {
    width: '100%',
    height: '100%',

    position: 'relative'
  },
  images_wrapper__image: {
    maxWidth: '100%',
    maxHeight: '100%',

    position: 'absolute',
    top: '0',
    left: '0',

    objectFit: 'cover',
  },
}));