import axios from 'axios';

export const getLayerTwoColors = () => {
  const endpoint = 'https://cc-cms.stopplay.app/api';

  const headers = {
    'content-type': 'application/json',
    Authorization: 'Bearer usbRS_kWf2DBjBPtvQGxc3TK94oAjw1z'
  };
  const graphqlQuery = {
    query: `query MyQuery {
    entries {
      ... on categoryColours_categoryColoursEntry_Entry {
        title
        layer2backgroundhex
        textcolor
      }
    }
  }
  `,
    variables: {}
  };

  const options = {
    method: 'POST',
    url: endpoint,
    headers: headers,
    data: graphqlQuery
  };

  const response = axios
    .request(options)
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      console.error(error);
    });

  return response;
};
