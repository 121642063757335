import { useEffect } from "react";

import { connect } from 'react-redux';
import { setUserData__browser } from '../../redux/reducers/userData/userData';
import {
  setArchivesEntriesData,
  setBlocksColors
} from '../../redux/reducers/layer-two/layerTwo';

import clsx from 'clsx';

import { detectBrowser } from '../../helpers/detectBrowser/detectBrowser';
import ImagesCarousel from './images-carousel/ImagesCarousel';
import CloseButton from '../../components/reusable/closeButton/closeButton'; 
import Layer1 from "../../components/Layer1/Layer1";
import LayerTwo from '../../components/layer-two/LayerTwo';
import Layer3 from "../../components/Layer3/Layer3";

import { getEntriesOnArchivePortfolio } from '../../queries/getEntriesOnArchivePortfolio';
import { getLayerTwoColors } from '../../queries/getLayerTwoColors';

import { homePageStyles } from './homePage.styles';


function HomePage({ reduxProps, reduxActions }) {
  
  const { currentLayer, displayOpacityPanel, backgroundColor } = reduxProps;
  
  const classes = homePageStyles({ currentLayer, backgroundColor });
  
  const { setUserData__browser, setArchivesEntriesData, setBlocksColors } = reduxActions;

  const loadAndSetArchivesEntriesData = async () => {
    await getEntriesOnArchivePortfolio().then(({ data: { entries } }) => {
      setArchivesEntriesData(entries);
    });

    await getLayerTwoColors().then(({ data: { entries } }) => {
      setBlocksColors(entries);
    });
  };

  useEffect(() => {

    setUserData__browser(detectBrowser());

    loadAndSetArchivesEntriesData();

  }, []);

  return (
    <div className={classes.homepage}>
      <CloseButton />

      {/* Move this into its own component */}
      <div
        className={clsx(
          classes.opacityPanel,
          displayOpacityPanel && classes._increaseOpacity,
          currentLayer !== 1 && classes._hideElement
        )}
      />

      <ImagesCarousel />

      <Layer1 />
      
      <LayerTwo />

      <Layer3 />
    </div>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    currentLayer: currentState.appReducer.currentLayer,
    displayOpacityPanel: currentState.appReducer.displayOpacityPanel,
    backgroundColor: currentState.appReducer.backgroundColor,
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setUserData__browser: (browser) => dispatch(setUserData__browser(browser)),
    setArchivesEntriesData: (archivesEntries) => dispatch(setArchivesEntriesData(archivesEntries)),
    setBlocksColors: (colors) => dispatch(setBlocksColors(colors))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);