import { connect } from 'react-redux';

import { useTheme, useMediaQuery } from '@mui/material';

import clsx from 'clsx';

import TitlesWrapper from './titles-wrapper/TitlesWrapper';
import ImagesToHighlight from './images-to-highlight/ImagesToHighlight';
import BlocksWrapper from './blocks-wrapper/BlocksWrapper';

import { useLayerTwoStyles } from './layerTwo.styles';


// Fix background color thinghy on mobile
const LayerTwo = ({ reduxProps }) => {

  const theme = useTheme();
  const desktopViewport = useMediaQuery(theme.breakpoints.up('md'));

  const { currentLayer } = reduxProps;
  
  const classes = useLayerTwoStyles({ currentLayer });

  return (
    <div
      id="layer_two"
      className={clsx(
        classes.layer_two,
        currentLayer === 2 ? classes._open : classes._closed
      )}
    >
      <TitlesWrapper />
      {desktopViewport && (
        <ImagesToHighlight />
      )}
      <BlocksWrapper />
    </div>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    currentLayer: currentState.appReducer.currentLayer,
  }
})

export default connect(mapStateToProps)(LayerTwo);
