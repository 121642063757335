import { useState, useRef, useMemo, useLayoutEffect } from 'react';

import { connect } from 'react-redux';
import {
  updateActiveBlockTrigger,
  updatePreviousBlockTrigger,
} from '../../../redux/reducers/layer-two/layerTwo';
import { setBackgroundColor } from '../../../redux/reducers/app/app';

import { useTheme, useMediaQuery, useScrollTrigger } from '@mui/material';

import Block from './block/Block';

import { useBlockWrapperStyles } from './blocksWrapper.styles';


const BlocksWrapper = ({ reduxProps, reduxActions }) => {
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  // So we can track if we're going up or down
  const [scrollDirectionTarget, setScrollDirectionTarget] = useState(undefined);
  const scrollDirection = useScrollTrigger({ target: scrollDirectionTarget });
  
  const {
    layerTwoProps: {
      threshold,
      activeBlock,
      previousBlock,
      blocksArray,
      blocksData
    },
    backgroundColor
  } = reduxProps;

  const classes = useBlockWrapperStyles({ threshold, backgroundColor });

  const {
    updateActiveBlockTrigger,
    updatePreviousBlockTrigger,
    setBackgroundColor
  } = reduxActions;
  
  // So we can get listen to scroll
  const blocksWrapper = useRef();

  // So we can get its height;
  const lastBlockRef = blocksArray[blocksArray.length - 1]?.ref ?? {};
  
  // Calculates last services block margin bottom
  const lastBlockMarginBottom = useMemo(() => {
    // In case servicesRowRef is not available
    if (!blocksWrapper.current) {
      return 0
    }

    let blocksWrapperHeight = blocksWrapper.current.offsetHeight;
    let lastBlockHeight = lastBlockRef.offsetHeight;

    if (mobileViewport) {

      blocksWrapperHeight = blocksWrapperHeight / 2;

      return `calc(${blocksWrapperHeight - lastBlockHeight}px - 1rem)`;
    }

    return `calc(${blocksWrapperHeight - lastBlockHeight}px - 2rem)`;
  }, [blocksWrapper.current, lastBlockRef.offsetHeight]);

  // Does what it says
  const scrollToTop = (ref) => {
    ref.scrollTo(0, 0);
  }

  const handleScroll = () => {
    // To prevent the app from crashing
    if (activeBlock.id === undefined) return

    // If we're going down, update active block's trigger. 
    // Else, update previous block's.
    if (scrollDirection) {
      updateActiveBlockTrigger(Math.floor(
        activeBlock.ref.getBoundingClientRect().bottom
      ));
    } else if (!scrollDirection && previousBlock.id !== undefined) {
      updatePreviousBlockTrigger(Math.floor(
        previousBlock.ref.getBoundingClientRect().bottom
      ));
    }

    return null;
  }

  useLayoutEffect(() => {
    scrollToTop(blocksWrapper.current);
    setScrollDirectionTarget(blocksWrapper.current);
  }, []);

  useLayoutEffect(() => {
    setBackgroundColor(activeBlock.backgroundColor);
  }, [activeBlock.backgroundColor]);

  return (
    <div
      ref={blocksWrapper}
      onScroll={handleScroll}
      className={classes.blocks_wrapper}
    >
      {blocksData.map((entryData, index) => (
        <Block
          key={index}
          drilledProps={{
            id: index,
            ...entryData,
            lastBlockMarginBottom:
              blocksData.length - 1 === index && lastBlockMarginBottom
          }}
        />
      ))}
    </div>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    layerTwoProps: currentState.layerTwoReducer.layerTwo,
    backgroundColor: currentState.appReducer.backgroundColor
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    updateActiveBlockTrigger: (trigger) =>
      dispatch(updateActiveBlockTrigger(trigger)),
    updatePreviousBlockTrigger: (trigger) => dispatch(updatePreviousBlockTrigger(trigger)),
    setBackgroundColor: (color) => dispatch(setBackgroundColor(color))
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(BlocksWrapper);