import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
      xxl: 2500
    }
  },
});

theme = createTheme(theme, {
  typography: {
    h1: {
      maxWidth: '46.875rem', // 750px

      fontSize: '2.4rem',
      lineHeight: '2.8rem',

      whiteSpace: 'pre-wrap',

      [theme.breakpoints.up('1300')]: {
        fontSize: '3rem',
        lineHeight: '3.35rem',
      },
      [theme.breakpoints.up('1500')]: {
        fontSize: '3.6rem',
        lineHeight: '3.9rem'
      },
      [theme.breakpoints.up('1700')]: {
        fontSize: '4.2rem',
        lineHeight: '4.45rem'
      },
      [theme.breakpoints.up('1900')]: {
        fontSize: '4.8rem',
        lineHeight: '5rem'
      }
    },
    body: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.625rem', // 26px,

      [theme.breakpoints.up('md')]: {
        fontSize: '1.8125rem', // 29px
        lineHeight: '2.375rem'
      }
    },
    body_underlined: {
      boxSizing: 'border-box',

      // To prevent underline from disappearing
      // when we increase textUnderlineOffset
      paddingBottom: '.3rem',

      fontSize: '1.25rem', // 20px
      lineHeight: '1.5rem', // 24px
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      textDecorationThickness: '0.1rem', // 1.6px
      textUnderlineOffset: '0.3rem', // 4.8px

      [theme.breakpoints.up('md')]: {
        fontSize: '1.8125rem', // 29px
        lineHeight: '2.375rem' // 38px
      }
    },
    body_bold: {
      fontFamily: 'Graphik Semibold !important',
      fontSize: '1.25rem', // 20px
      lineHeight: '1.625rem', // 26px,

      [theme.breakpoints.up('md')]: {
        fontSize: '1.8125rem', // 29px
        lineHeight: '2.375rem'
      }
    },
    body_bold_underlined: {
      boxSizing: 'border-box',

      // To prevent underline from disappearing
      // when we increase textUnderlineOffset
      paddingBottom: '.3rem',

      fontFamily: 'Graphik Semibold !important',
      fontSize: '1.25rem', // 20px
      lineHeight: '1.5rem', // 24px
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      textDecorationThickness: '0.125rem', // 2px
      textUnderlineOffset: '0.3rem', // 4.8px

      [theme.breakpoints.up('md')]: {
        fontSize: '1.8125rem', // 29px
        lineHeight: '2.375rem' // 38px
      }
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        margin: '0',

        fontFamily: 'Graphik',
        fontWeight: '400',

        variantMapping: {
          body: 'p',
          body_underlined: 'p',
          body_bold: 'p',
          body_bold_underlined: 'p'
        }
      }
    }
  }
});

export default theme;