import { makeStyles } from '@mui/styles';

export const useTitlesWrapperStyles = makeStyles((theme) => ({
  titles_wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    height: '50%',

    padding: '1rem',

    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    top: '0',
    zIndex: '4',

    pointerEvents: 'none',

    [theme.breakpoints.down('md')]: {
      transition: 'background-color .2s ease-in-out !important',
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '100%',

      padding: '2rem',
    }
  },
  titles_inner_wrapper: ({ translateY }) => ({
    marginTop: 'auto',

    transition: 'all .2s ease-in-out',
    transform: `translateY(${translateY}px)`
  })
}));