import { connect } from 'react-redux';

import { Typography } from '@mui/material';

import clsx from 'clsx';

import { AnimatedText } from './animated-text/AnimatedText';

import { useBlockStyles } from '../block.styles';


const ArchivesBlockContent = ({ drilledProps, reduxProps }) => {

  const { id, textColor } = drilledProps;

  const { activeBlock } = reduxProps;

  const classes = useBlockStyles({
    id,
    activeBlockId: activeBlock.id,
    textColor: activeBlock.textColor
  });

  return (
    /* 
      This specific block has a div wrapped around it to make it easier to control 
      opacity when imageToBeHighlighted is active or activeBlockId !== id (this block's id)
    */
    <div className={classes.block_text_wrapper}>
      <Typography variant="body_bold" className={classes.block__text}>
        For further information <br />
      </Typography>
      <Typography
        variant="body_bold_underlined"
        component="a"
        className={clsx(classes.block__text, classes.block__cursor_text)}
        href="mailto: info@contemporary-culture.com"
      >
        email
      </Typography>
      <Typography
        variant="body_bold"
        component="span"
        className={classes.block__text}
      >
        {' '}or{' '}
      </Typography>
      <AnimatedText
        drilledProps={{
          id,
          activeBlock
        }}
      />
    </div>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
    imageToBeHighlightedId:
      currentState.layerTwoReducer.layerTwo.imagesToHighlight.id
  }
});


export default connect(mapStateToProps)(ArchivesBlockContent);
