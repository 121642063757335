import { connect } from 'react-redux';
import {
  setPreviousLayer,
  setCurrentLayer,
  setBackgroundColor
} from '../../../../../redux/reducers/app/app';
import {
  setImageToBeHighlighted
} from '../../../../../redux/reducers/layer-two/layerTwo';
import { setLayerThreeContent } from '../../../../../redux/reducers/layer-three/layerThree';

import { useTheme, useMediaQuery, Typography } from '@mui/material';

import clsx from 'clsx';

import { useBlockStyles } from '../block.styles';


const BioBlockContent = ({ drilledProps, reduxProps, reduxActions }) => {
  const theme = useTheme();
  const desktopViewport = useMediaQuery(theme.breakpoints.up('md'));

  const { id, textColor, bioText, bioContent } = drilledProps;

  const { activeBlock, imageToBeHighlightedId, currentLayer, cleaningData } = reduxProps;

  const classes = useBlockStyles({
    id,
    activeBlockId: activeBlock.id,
    textColor: activeBlock.textColor,
    cleaningData
  });
  
  const {
    setLayerThreeContent,
    setPreviousLayer,
    setCurrentLayer,
    setImageToBeHighlighted,
    setBackgroundColor
  } = reduxActions;

  const openBioLayerThree = () => {
    const {
      backgroundHex: backgroundColor,
      bodyTextHex: textColor,
      header: headerText,
      bodytext: bodyText,
      media: images
    } = bioContent;

    setLayerThreeContent({
      textColor,
      backgroundColor,
      headerText,
      bodyText,
      images
    });

    setPreviousLayer(2);
    setCurrentLayer(3);
  };

  const handleMouseEnter = () => {
    if (desktopViewport) {
      setImageToBeHighlighted(bioContent.media[0].id);
      setBackgroundColor(activeBlock.backgroundColor);
    }

    return null;
  }

  const handleMouseLeave = () => {
    if (desktopViewport) {
      setImageToBeHighlighted(null);
    }

    // This is to prevent the background color from changing
    // during layer transition
    if (currentLayer === 2) {
      setBackgroundColor(activeBlock.backgroundColor);
    }

    return null;
  }

  return (
    <>
      <Typography
        variant="body"
        component="span"
        className={clsx(
          classes.block__text,
          // This hides the text if imageToBeHighlighted exists
          // or if active
          imageToBeHighlightedId && classes._hidden,
          activeBlock.id !== id && classes._hidden
        )}
      >
        contemporary.culture.ltd <br />
        by{' '}
      </Typography>
      <Typography
        variant="body_underlined"
        component="span"
        className={clsx(
          classes.block__text,
          classes.block__cursor_text,
          // This hides the text if imageToBeHighlighted exists,
          // unless you've got your mouse on top of it.
          imageToBeHighlightedId &&
            imageToBeHighlightedId !== bioContent.media[0].id &&
            classes._hidden,
          activeBlock.id !== id && classes._hidden
        )}
        onClick={() => openBioLayerThree()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Dr. Michaela Neumeister de Pury
      </Typography>
      <Typography
        variant="body"
        className={clsx(
          classes.block__text,
          imageToBeHighlightedId && classes._hidden,
          activeBlock.id !== id && classes._hidden
        )}
      >
        {bioText}
      </Typography>
    </>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
    imageToBeHighlightedId:
      currentState.layerTwoReducer.layerTwo.imagesToHighlight.id,
    currentLayer: currentState.appReducer.currentLayer,
    cleaningData: currentState.layerThreeReducer.layerThree.cleaningData
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setPreviousLayer: (layer) => dispatch(setPreviousLayer(layer)),
    setCurrentLayer: (layer) => dispatch(setCurrentLayer(layer)),
    setLayerThreeContent: (layerThreeContent) =>
      dispatch(setLayerThreeContent(layerThreeContent)),
    setImageToBeHighlighted: (imageId) =>
      dispatch(setImageToBeHighlighted(imageId)),
    setBackgroundColor: (color) => dispatch(setBackgroundColor(color))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BioBlockContent);