// REDUX
import { combineReducers } from 'redux';
// REDUCERS
import { app } from './app/app';
import { userData } from './userData/userData';
import { layerTwo } from './layer-two/layerTwo';
import { layerThree } from './layer-three/layerThree';

export default combineReducers({
  appReducer: app,
  userDataReducer: userData,
  layerTwoReducer: layerTwo,
  layerThreeReducer: layerThree,
});