// ASB = Active Block
// PSB = Previous Block

function updatedTrigger(currentActiveBlockState, updatedTrigger) {
  return {
    ...currentActiveBlockState,
    trigger: updatedTrigger
  };
}

function updatedPB (currentLayerTwoState) {

  const blocksArray = currentLayerTwoState.blocksArray;
  const currentAB = currentLayerTwoState.activeBlock;

  const previousBlock = blocksArray.find(
    (block) => block.id === currentAB.id
  );

  return previousBlock;
}

function updatedAB (currentLayerTwoState) {

  const blocksArray = currentLayerTwoState.blocksArray;
  const currentAB = currentLayerTwoState.activeBlock;

  const nextBlock = blocksArray.find((block) => (
    block.id === currentAB.id + 1
  ))

  if (nextBlock) {
    return nextBlock;
  }

  return {
    ...currentAB,
  }
}

export function handleActiveBlockUpdate (currentLayerTwoState, trigger) {

  if (currentLayerTwoState.threshold > trigger) {

    return {
      ...currentLayerTwoState,
      previousBlock: updatedPB(currentLayerTwoState),
      activeBlock: updatedAB(currentLayerTwoState)
    };
  }

  return {
    ...currentLayerTwoState,
    activeBlock: updatedTrigger(
      currentLayerTwoState.activeBlock,
      trigger
    )
  };
}