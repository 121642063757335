/*
  We receive an image tag in the form of a string from the CMS.
  This function transforms that string into an actual image tag.
*/

function transformIntoImageTag(string) {
  if (!string) return null;
  let objectToReturn = {
    src: extractImgSrc(),
    alt: extractImgAlt(),
    orientation: imgOrientation(),
  };
  return objectToReturn || null;

  function extractImgSrc() {
    let stringProps = string.split(" ");
    let srcEntireProp = stringProps.filter(
      (prop) => prop.slice(0, 3) === `src`
    );
    let url = String(srcEntireProp).split('"')[1];

    if (url) {
      return url;
    }
    return null;
  }

  function extractImgAlt() {
    let stringProps = string.split('"');
    let alt = stringProps[7];
    if (alt) {
      return alt;
    }
    return null;
  }

  function imgOrientation() {
    let height = extractImgHeight();
    let width = extractImgWidth();
    if (parseInt(height) > parseInt(width)) {
      return "Portrait";
    }
    return "Landscape";
  }

  function extractImgHeight() {
    let stringProps = string.split(" ");
    let srcEntireProp = stringProps.filter(
      (prop) => prop.slice(0, 6) === `height`
    );
    let height = String(srcEntireProp).split('"')[1];

    if (height) {
      return height;
    }
    return null;
  }

  function extractImgWidth() {
    let stringProps = string.split(" ");
    let srcEntireProp = stringProps.filter(
      (prop) => prop.slice(0, 5) === `width`
    );
    let width = String(srcEntireProp).split('"')[1];

    if (width) {
      return width;
    }
    return null;
  }
}

export default transformIntoImageTag;
