// MATERIAL UI
import { makeStyles } from '@mui/styles';

export const logoStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    height: '10%',

    cursor: 'pointer',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    transform: 'scale(1.55)',

    '& div': {
      pointerEvents: 'none'
    },
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-1.5rem)',

      width: '84%',
      height: '12.5rem'
    }
  }
}));