// MATERIAL UI
import { makeStyles } from '@mui/styles';

export const closeButtonStyles = makeStyles((theme) => ({
  closeButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    zIndex: '9999999',

    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    },

    [theme.breakpoints.up('1280')]: {
      top: '0',
      right: '0',
      bottom: 'unset'
    }
  },
  closeButton: {
    boxSizing: 'border-box',

    cursor: 'pointer',

    background: 'unset',

    border: 'unset',

    padding: '.5rem',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton__icon: {
    width: '2rem',
    height: '2rem',

    fill: ({ textColor }) => textColor ? textColor : '#ff91f6',
  },
  closeButton__backgroundFilter: {
    height: '60px',
    width: '60px',

    background: ({ backgroundColor }) => backgroundColor,

    transition: 'background .5s ease-in-out',

    filter: 'blur(16px)',
    position: 'absolute',
    zIndex: '-1'
  }
}));
