import { connect } from 'react-redux';

import { Typography } from '@mui/material';

import { useBlockStyles } from '../block.styles';

const ServicesBlockContent = ({ drilledProps, reduxProps }) => {
  const { id, textColor, servicesArray } = drilledProps;

  const { activeBlock } = reduxProps;

  const classes = useBlockStyles({
    id,
    activeBlockId: activeBlock.id,
    textColor: activeBlock.textColor
  });

  return (
    /* 
      This specific block has a div wrapped around it to make it easier to control 
      opacity when imageToBeHighlighted is active or activeBlockId !== id (this block's id)
    */
    <div className={classes.block_text_wrapper}>
      {servicesArray.map((service, index) => (
        <Typography key={index} variant="body" className={classes.block__text}>
          {service.title}
        </Typography>
      ))}
    </div>
  );
};

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
    imageToBeHighlightedId:
      currentState.layerTwoReducer.layerTwo.imagesToHighlight.id
  }
});

export default connect(mapStateToProps)(ServicesBlockContent);
