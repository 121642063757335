import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import theme from './material-ui/theme';

import HomePage from './pages/home-page/homePage';

import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          component={() => (
            <ThemeProvider theme={theme}>
              <HomePage />
            </ThemeProvider>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
