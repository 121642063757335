import React, { useRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import { titleStyles } from './title.styles';

export const Title = React.memo(({ children, drilledProps }) => {

  const { id, activeBlock, addTitleRef } = drilledProps;

  const classes = titleStyles({ id, activeBlock });

  const titleRef = useRef(<div />);

  useEffect(() => {
    addTitleRef({
      id,
      // Adding its ref just in case we need it later
      ref: titleRef.current,
    });
  }, [addTitleRef, id]);

  return (
    <Typography ref={titleRef} variant="h1" className={classes.title}>
      {children}
    </Typography>
  );
});
