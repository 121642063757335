// MATERIAL UI
import { makeStyles } from '@mui/styles';

export const useImagesCarouselStyles = makeStyles({
  images_carousel_wrapper: {
    height: '100%',
    width: '100%',

    background: '#000959'
  },
  images_carousel: {
    height: '100%',
    width: '100%',

    position: 'absolute',
    zIndex: '3',

    overflow: 'hidden',

    '& .carousel-slider': {
      height: '100%'
    },
    '& .slider-wrapper': {
      height: '100%'
    },
    '& ul': {
      height: '100%',

      margin: '0',
      padding: '0',

      display: 'flex',
      flexDirection: 'column'
    },
    '& button': {
      display: 'none'
    }
  },
  carouselImg: {
    width: '100%',
    height: '100%',

    objectFit: 'cover',

    pointerEvents: 'none'
  }
});
