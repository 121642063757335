const INITIAL_STATE = {
  userData: {
    browser: '',
  }
};

export const userData = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER_DATA__BROWSER':
      return {
        ...currentState,
        userData: {
          ...currentState.userData,
          browser: action.payload
        }
      };
    default:
      return currentState;
  }
};

export const setUserData__browser = (browser) => ({
  type: 'SET_USER_DATA__BROWSER',
  payload: browser
});
