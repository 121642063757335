import { useState, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { setThreshold } from '../../../redux/reducers/layer-two/layerTwo';

import { useTheme, useMediaQuery, Fade } from '@mui/material';

import { Title } from './title/Title';

import { useTitlesWrapperStyles } from './titlesWrapper.styles';

const TitlesWrapper = ({ reduxProps, reduxActions }) => {
  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('md'));
  
  const [titleRefsArray, addTitleRef] = useState([]);
  const [translateY, setTranslateY] = useState(0);

  const { activeBlock, blocksData, backgroundColor, imageToHighlightId } = reduxProps;
  const { setThreshold } = reduxActions;

  const classes = useTitlesWrapperStyles({
    translateY,
    backgroundColor
  });

  const titlesWrapperRef = useRef(<div />);
  
  const handleTitleRefAddition = (titleRefToAdd) => {
    
    // Check if title ref already exists in array
    const titleRefExists = titleRefsArray.find((titleRef) => titleRef.id === titleRefToAdd.id);
    
    // If true, return out of function
    if (titleRefExists) return;
    
    // Else, add it to array
    addTitleRef((titleRefsArray) => titleRefsArray.concat(titleRefToAdd));
  }
  
  const calculateTranslateY = () => {
    let totalTitlesHeight = 0;

    if (activeBlock.id === 0) {
      return totalTitlesHeight;
    } else {

      titleRefsArray.slice().reverse().slice(0, activeBlock.id).forEach((titleRef) => {
        totalTitlesHeight += titleRef.ref.getBoundingClientRect().height
      });
  
      return totalTitlesHeight;
    }
  }

  const calculateAndSetThreshold = () => {
    let threshold;

    // The 76 here represents the height of 2 pararaphs.
    if (mobileViewport) {
      threshold = Math.floor(
        titlesWrapperRef.current.getBoundingClientRect().bottom + 76
      );
    } else {
      threshold =
        Math.floor(titlesWrapperRef.current.getBoundingClientRect().top) + 76;
    }

    setThreshold(threshold);
  }

  const scrollToBottom = () => {
    titlesWrapperRef.current.scrollTo(0, titlesWrapperRef.current.offsetHeight);
  };

  useEffect(() => {

    setTranslateY(calculateTranslateY());

  }, [activeBlock.id]);

  useEffect(() => {

    calculateAndSetThreshold();

    // We can only call scroll to bottom when the page has fully loaded.
    // Due to the large font-size, some titles collapse into multiple
    // paragraphs, causing their height to change. This height change
    // is not detected by react until the page loads, so if we set it
    // as a dependency for this useEffect, it won't work.
    window.addEventListener('load', scrollToBottom);

    return () => {
      window.removeEventListener('load', scrollToBottom);
    };
  }, [titlesWrapperRef.current]);

  return (
    <Fade in={!imageToHighlightId} timeout={200}>
      <div ref={titlesWrapperRef} className={classes.titles_wrapper}>
        <div className={classes.titles_inner_wrapper}>
          {/* 
            .reverse() mutates the original array's value. In order to prevent it,
            we need to make a copy of it by calling .slice()
          */}
          {blocksData
            .slice()
            .reverse()
            .map((serviceCategory, index) => (
              <Title
                key={index}
                drilledProps={{
                  id: serviceCategory.id,
                  activeBlock: activeBlock,
                  addTitleRef: handleTitleRefAddition
                }}
              >
                {serviceCategory.title}
              </Title>
            ))}
        </div>
      </div>
    </Fade>
  );
}

const mapStateToProps = (currentState) => ({
  reduxProps: {
    activeBlock: currentState.layerTwoReducer.layerTwo.activeBlock,
    blocksData: currentState.layerTwoReducer.layerTwo.blocksData,
    backgroundColor: currentState.appReducer.backgroundColor,
    imageToHighlightId: currentState.layerTwoReducer.layerTwo.imagesToHighlight.id,
  }
});

const mapDispatchToProps = (dispatch) => ({
  reduxActions: {
    setThreshold: (threshold) => dispatch(setThreshold(threshold))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TitlesWrapper);
