import { handleBlockAddition } from './layer-two-utils/handleBlockAdditon';
import { handlePreviousBlockUpdate } from './layer-two-utils/handlePreviousBlockUpdate';
import { handleActiveBlockUpdate } from './layer-two-utils/handleActiveBlockUpdate';
import { handleSetArchivesEntriesData } from './layer-two-utils/handleSetArchivesEntriesData';
import { handleSetBlocksColors } from './layer-two-utils/handleSetBlocksColors';

import { mockedBlocksData } from './mockedBlocksData/mockedBlocksData';

const INITIAL_STATE = {
  layerTwo: {
    threshold: null,
    previousBlock: {},
    activeBlock: {},
    blocksArray: [],
    blocksData: mockedBlocksData.slice(),
    imagesToHighlight: {
      id: null,
      images: []
    }
  }
};

export const layerTwo = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_THRESHOLD':
      return {
        ...currentState,
        layerTwo: {
          ...currentState.layerTwo,
          threshold: action.payload
        }
      };
    case 'ADD_TO_BLOCKS_ARRAY':
      return {
        ...currentState,
        layerTwo: handleBlockAddition(currentState.layerTwo, action.payload)
      };
    case 'UPDATE_PREVIOUS_BLOCK_TRIGGER':
      return {
        ...currentState,
        layerTwo: handlePreviousBlockUpdate(currentState.layerTwo, action.payload)
      };
    case 'UPDATE_ACTIVE_BLOCK_TRIGGER':
      return {
        ...currentState,
        layerTwo: handleActiveBlockUpdate(currentState.layerTwo, action.payload)
      };
    case 'SET_ARCHIVES_ENTRIES_DATA':
      return {
        ...currentState,
        layerTwo: handleSetArchivesEntriesData(
          currentState.layerTwo,
          action.payload
        )
      };
    case 'SET_BLOCKS_COLORS':
      return {
        ...currentState,
        layerTwo: {
          ...currentState.layerTwo,
          blocksData: handleSetBlocksColors(currentState.layerTwo.blocksData, action.payload)
        }
      };
    case 'SET_IMAGE_TO_BE_HIGHLIGHTED':
      return {
        ...currentState,
        layerTwo: {
          ...currentState.layerTwo,
          imagesToHighlight: {
            ...currentState.layerTwo.imagesToHighlight,
            id: action.payload
          }
        }
      };
    default:
      return currentState;
  } 
}

export const setThreshold = (threshold) => ({
  type: 'SET_THRESHOLD',
  payload: threshold,
});

export const addToBlocksArray = (block) => ({
  type: 'ADD_TO_BLOCKS_ARRAY',
  payload: block
});

export const updateActiveBlockTrigger = (trigger) => ({
  type: 'UPDATE_ACTIVE_BLOCK_TRIGGER',
  payload: trigger
});

export const updatePreviousBlockTrigger = (trigger) => ({
  type: 'UPDATE_PREVIOUS_BLOCK_TRIGGER',
  payload: trigger
});

export const setArchivesEntriesData = (archivesEntries) => ({
  type: 'SET_ARCHIVES_ENTRIES_DATA',
  payload: archivesEntries
});

export const setBlocksColors = (colors) => ({
  type: 'SET_BLOCKS_COLORS',
  payload: colors
});

export const setImageToBeHighlighted = (imageId) => ({
  type: 'SET_IMAGE_TO_BE_HIGHLIGHTED',
  payload: imageId
});
