export const mockedBlocksData = [
  {
    id: 0,
    title: 'About',
    bioText: `specializes in bespoke art &
    design solutions. Our services
    are built on the core
    philosophy that every client
    has an individual approach to
    art and culture. Responding
    with unparalleled insight, our
    art market expertise spans
    from Classic and Impressionist
    to Contemporary Art,
    encompassing all media and
    periods from emerging talent
    to blue chip.`
  },
  {
    id: 1,
    title: 'Art&Design Advisory',
    services: [
      {
        title: 'Global Sourcing & Access'
      },
      {
        title: 'Comissions'
      },
      {
        title: 'Collection Building Strategies'
      },
      {
        title: 'Art Investment'
      },
      {
        title: 'De-accessioning & Sales'
      },
      {
        title: 'Auction Services'
      },
      {
        title: 'Art Marketing Coaching'
      },
      {
        title: 'Research'
      },
      {
        title: 'Education'
      },
      {
        title: 'Strategic Consulting for Artists'
      },
      {
        title: 'Artist Liaison'
      },
      {
        title: 'Studio Visits'
      },
      {
        title: 'Guided Art Tours'
      },
      {
        title: 'Auction, Gallery & Fair Previews'
      },
      {
        title: 'Shipping'
      },
      {
        title: 'Storage'
      },
      {
        title: 'Tax Expertise'
      }
    ]
  },
  {
    id: 2,
    title: 'Art&Design Collection Management',
    services: [
      {
        title: 'Collection Evaluation'
      },
      {
        title: 'Inventory Database Organization'
      },
      {
        title: 'Framing'
      },
      {
        title: 'Installation'
      },
      {
        title: 'Lightning'
      },
      {
        title: 'Climate Control'
      },
      {
        title: 'Authentication'
      },
      {
        title: 'Conservation'
      },
      {
        title: 'Restoration'
      },
      {
        title: 'Art Loans Co-ordination'
      },
      {
        title: 'Transport'
      },
      {
        title: 'Insurance'
      },
      {
        title: 'Security'
      },
      {
        title: 'Trusts & Estates Planning'
      }
    ]
  },
  {
    id: 3,
    title: 'Curating & Exhibition Services',
    services: [
      {
        title: 'Temporary & Permanent Curatorial Concepts'
      },
      {
        title: 'Venue Sourcing'
      },
      {
        title: 'Collaborations'
      },
      {
        title: 'Installation'
      },
      {
        title: 'Logistics'
      },
      {
        title: 'Educational Programming'
      },
      {
        title: 'Special Events'
      },
      {
        title: 'PR & Publications'
      }
    ]
  },
  {
    id: 4,
    title: 'Real Estate',
    services: [
      {
        title: 'Branding through Architecture, Art & Design'
      },
      {
        title: 'Artist Selection & Tender Competition'
      },
      {
        title: 'Interior Concepts'
      },
      {
        title: 'Public Space Exhibitions'
      },
      {
        title: 'Special Art Commissions'
      },
      {
        title: 'Installation'
      },
      {
        title: 'Logistics'
      },
      {
        title: 'Project PR'
      }
    ]
  },
  {
    id: 5,
    title: 'Charity Concepts',
    services: [
      {
        title: 'Fundrasing for Institutions'
      },
      {
        title: 'Charity Auction Concepts'
      },
      {
        title: 'Collaborations & Partnerships'
      }
    ]
  },
  {
    id: 6,
    title: 'PR & Press',
    services: [
      {
        title: 'Media Liaison'
      },
      {
        title: 'Collection Profiling'
      },
      {
        title: 'Exhibition Exposure'
      }
    ]
  },
  {
    id: 7,
    title: 'Archive',
    archives: []
  },
  {
    id: 8,
    title: 'Contacts'
  }
];
